




















































































































import Vue from "vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Superscript from "@tiptap/extension-superscript";
import Subscript from "@tiptap/extension-subscript";

export default Vue.extend({
  components: {
    EditorContent,
  },
  model: {
    prop: "content",
    event: "onChange",
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
  },

  data() {
    return {
      editor: {} as Editor,
    };
  },
  watch: {
    content(newVal) {
      if (this.editor) {
        const isSame = this.editor.getHTML() === newVal;
        if (isSame) {
          return;
        }
        this.editor.commands.setContent(newVal, false);
      }
    },
  },

  mounted() {
    console.log(this.content);
    const vm = this;
    this.editor = new Editor({
      content: this.content,
      extensions: [StarterKit, Superscript, Subscript],
      editorProps: {
        attributes: {
          class: "border border-solid border-gray-700 p-2",
        },
      },
      onUpdate() {
        vm.$emit("onChange", vm.editor.getHTML());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
});
